@font-face {
  font-family: 'Noto Sans';
  src: url('/fonts/Noto_Sans_TC/static/NotoSansTC-Light.ttf');
  src: url('/fonts/Noto_Sans_TC/static/NotoSansTC-Light.ttf') format('truetype')
}

html {
  height: 100vh;
  width: 100vw;
  font-family: 'Noto Sans';
}

.body {
  height: 100%;
  width: 100%;
}

.position-top-left {
  position: absolute;
  top: 0;
  left: 0;
}

#header {
  padding: 5% 0 0;
  height: 8%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.header-image {
  width: clamp(270px, 80%, 80%);
}

#resetPasswordForm {
  height: clamp(450px, 100vh, 100vh);
  width: clamp(200px, 60vw, 60vw);
  font-size: x-large;
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start; 
  padding: 0 20vw;
}

.inputDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.inputLabel {
  margin: 1% 0;
}

.inputPassword {
  height: clamp(40px, 5vw, 5vw);
  font-size: 16px;
  border-radius: 4px;
  border: solid 1px #ccc;
  padding: 0 2%;
}

#submitButton {
  height: clamp(35px, 5%, 5%);
  width: 100%;
  border-radius: 4vw;
  border: none;
  background-color: #38BDC8;
  color: white;
  font-weight: bold;
  margin-top: 10%;
}

#emailVerifyResult {
  display: flex;
}


#resetPasswordResult {
  display: none;
}

.result {
  height: clamp(450px, 100vh, 100vh);
  width: 100vw;
  font-size: x-large;
  text-align: center;
  justify-content: center;
  align-items: center; 
}

#loading {
  animation: rotation 0.7s infinite ease-in-out;
}

@keyframes rotation {
  0% {transform: rotate(0deg);}
  25% {transform: rotate(50deg);}
  50% {transform: rotate(180deg);}
  75% {transform: rotate(310deg);}
  100% {transform: rotate(360deg);}
}

.image-asset {
  width: min(30%, 7rem);
}

#resultSuccess {
  color: green;
}

#resultFailed {
  color: red;
}

.resultMessage {
  font-size: medium;
  color: grey
}
